/*=========================
    Portfolio Slide 
============================*/



.single-portfolio-slide{
    position: relative;
    .fullscreen{
        @media #{$sm-layout} {
            min-height: auto;
            padding: 150px 0;
        }
    }
    .inner{
        text-align: left;
        max-width: 600px;
        background: #000;
        padding: 64px 68px 62px;
        position: relative;
        left: -100px;

        @media #{$sm-layout} {
            left: 0;
            padding: 44px 33px 32px;
        }
        @media #{$md-layout} {
            left: -50px;
        }

        .info{

            h3{
                &.heading{
                    margin-bottom: 18px;
                    font-family: var(--ff-heading);
                    a{
                        color: #ffffff;
                    }
                }
            }
            .category{
                margin-bottom: 36px;
                a{
                    font-size: 16px;
                    color: #999;
                    font-family: var(--ff-heading);
                }
            }
            .slide-btn{
                a{
                    box-shadow: none;
                    border-radius: 40px;
                    border: 2px solid hsla(0,0%,100%,.3);
                    height: 57px;
                    line-height: 53px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #fff;
                    background: transparent;
                    padding: 0 36px;
                    position: relative;
                    display: inline-block;
                    &:hover{
                        background: #fff;
                        color: #222;
                        -webkit-transform: translateY(-3px);
                        -ms-transform: translateY(-3px);
                        transform: translateY(-3px);
                    }
                }
            }
        }
    }
}

















