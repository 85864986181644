/* =========================
    flexible Image Area
===========================*/

.flexible-image {
  .thumb {
    img {
      width: 100%;
    }
  }
}


.flexible-carousle-activation--2 .swiper-wrapper {
  .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;

    .swiper-slide {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      position: relative;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      box-sizing: border-box;
    }
  }
}

.flexible-carousle-activation--2 .swiper-slide,
.flexible-carousle-activation--2 .swiper-slide img,
.flexible-carousle-activation--2 .swiper-slide .thumb,
.flexible-carousle-activation--2 .swiper-slide {
  width: auto !important;
}

.flexible-carousle-activation--2 {
  .swiper-slide {
    img {
      height: 678px;
      max-width: 100%;
      vertical-align: top;
      @media #{$sm-layout} {
        height: 238px
      }
    }
  }
}


// Fleaxable Arrow Style
.flexible-arrow {
  position: relative;

  .slick-btn {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    left: 50px;
    z-index: 9;

    &::before {
      display: none;
    }

    @media #{$md-layout} {
      left: 16px;
    }

    @media #{$sm-layout} {
      left: 16px;
    }

    i {
      color: #ffffff;
      font-size: 20px;
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1), visibility .3s linear 2s, opacity .3s linear 2s;
      transition: all .3s cubic-bezier(.645, .045, .355, 1), visibility .3s linear 2s, opacity .3s linear 2s;
    }

    &:hover {
      i {
        color: var(--clr-theme);
      }
    }

    &.slick-next {
      right: 50px;
      left: auto;

      @media #{$md-layout} {
        left: auto;
        right: 16px;
      }

      @media #{$sm-layout} {
        left: auto;
        right: 16px;
      }
    }
  }

  &:hover {
    .slick-btn {
      opacity: 1;
    }
  }
}


// flexible Dots
.flexible-dots {
  position: relative;

  .slick-dots {
    position: absolute;
    bottom: 50px;
    z-index: 2;

    @media #{$sm-layout} {
      bottom: 15px;
    }

    li {
      width: auto;
      height: auto;
      margin: 0 9px;
      padding: 0;

      button {
        &::before {
          display: none;
        }

        width: 10px;
        height: 10px;
        padding: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background: hsla(0, 0%, 100%, .2);
        border-radius: 100%;
      }

      &.slick-active {
        button {
          background: #ffffff;
        }
      }
    }
  }
}








