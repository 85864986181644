/* ====================
    Toolbar Area 
========================*/




.brook__toolbar{
    .inner{
        position: absolute;
        top: 200px;
        right: 100%;
        display: block;
        width: 40px;
        border: 1px solid #eee;
        border-right: 0;
        border-radius: 5px 0 0 5px;
        background: #fff;
        text-align: center;
        box-shadow: -3px 0 10px -2px rgba(0,0,0,.1);

        a{
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: #222;
            -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
            transition: all .3s cubic-bezier(.645,.045,.355,1);
            
            i{
                font-size: 16px;
            }

            &[class*=hint--] {
                position: relative;
                display: inline-block;
                -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
                transition: all .3s cubic-bezier(.645,.045,.355,1);
                &::before,
                &::after{
                    position: absolute;
                    -webkit-transform: translateZ(0);
                    transform: translateZ(0);
                    visibility: hidden;
                    opacity: 0;
                    z-index: 1000000;
                    pointer-events: none;
                    -webkit-transition: .3s ease;
                    transition: .3s ease;
                    -webkit-transition-delay: 0ms;
                    transition-delay: 0ms;
                }
                &::before{
                    content: "";
                    position: absolute;
                    background: transparent;
                    border: 7px solid transparent;
                    z-index: 1000001;
                    right: 100%;
                    bottom: 50%;
                    margin-right: -13px;
                    margin-bottom: -7px;
                    border-left-color: #000;

                }
                &::after{
                    content: attr(aria-label);
                    background: #000;
                    color: #fff;
                    padding: 8px 10px;
                    font-size: 14px;
                    line-height: 14px;
                    white-space: nowrap;
                    border-radius: 5px;
                    box-shadow: 4px 4px 8px rgba(0,0,0,.3);
                }

            }

            &.hint--bounce{
                -webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s cubic-bezier(.71,1.7,.77,1.24);
                transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s cubic-bezier(.71,1.7,.77,1.24);
                transition: opacity .3s ease,visibility .3s ease,transform .3s cubic-bezier(.71,1.7,.77,1.24);
                transition: opacity .3s ease,visibility .3s ease,transform .3s cubic-bezier(.71,1.7,.77,1.24),-webkit-transform .3s cubic-bezier(.71,1.7,.77,1.24);
            }

            &.hint--left{
                &::after{
                    margin-bottom: -15px;
                    right: 100%;
                    bottom: 50%;
                }
            }

            &:hover{
                color: var(--clr-theme);
                &[class*=hint--] {
                    &::before,
                    &::after{
                        visibility: visible;
                        opacity: 1;
                        -webkit-transition-delay: .1s;
                        transition-delay: .1s;
                    }
                    &::before{
                        -webkit-transform: translateX(-8px);
                        -ms-transform: translateX(-8px);
                        transform: translateX(-8px);
                    }
                    &::after{
                        -webkit-transform: translateX(-8px);
                        -ms-transform: translateX(-8px);
                        transform: translateX(-8px);
                    }
    
                }
                &.hint--bounce{
                    
                }
    
                &.hint--left{
                    
                   
                }




            }



        }
    }
}

















